/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as AppImport } from './routes/_app'
import { Route as IndexImport } from './routes/index'
import { Route as AppOrganizationImport } from './routes/_app/organization'
import { Route as AppDashboardImport } from './routes/_app/dashboard'
import { Route as AppAccountImport } from './routes/_app/account'
import { Route as AppReportsIndexImport } from './routes/_app/reports.index'
import { Route as AppProjectsIndexImport } from './routes/_app/projects.index'
import { Route as AppProductsIndexImport } from './routes/_app/products.index'
import { Route as AppLocationsIndexImport } from './routes/_app/locations.index'
import { Route as AppCustomersIndexImport } from './routes/_app/customers.index'
import { Route as AppAssetsIndexImport } from './routes/_app/assets.index'
import { Route as AppReportsUpcomingProjectsImport } from './routes/_app/reports.upcoming-projects'
import { Route as AppReportsUpcomingProjectStartsImport } from './routes/_app/reports.upcoming-project-starts'
import { Route as AppReportsUpcomingProjectCompletionsImport } from './routes/_app/reports.upcoming-project-completions'
import { Route as AppReportsTransitImport } from './routes/_app/reports.transit'
import { Route as AppReportsPastProjectsImport } from './routes/_app/reports.past-projects'
import { Route as AppReportsInventoryImport } from './routes/_app/reports.inventory'
import { Route as AppReportsFieldImport } from './routes/_app/reports.field'
import { Route as AppReportsActiveProjectsImport } from './routes/_app/reports.active-projects'
import { Route as AppProjectsProjectKeyImport } from './routes/_app/projects.$projectKey'
import { Route as AppProductsProductKeyImport } from './routes/_app/products.$productKey'
import { Route as AppDashboardProductImport } from './routes/_app/dashboard.product'
import { Route as AppDashboardLocationImport } from './routes/_app/dashboard.location'
import { Route as AppDashboardCustomerImport } from './routes/_app/dashboard.customer'
import { Route as AppAssetsAssetKeyImport } from './routes/_app/assets.$assetKey'
import { Route as AppProjectsProjectKeyIndexImport } from './routes/_app/projects.$projectKey.index'
import { Route as AppProductsProductKeyIndexImport } from './routes/_app/products.$productKey.index'
import { Route as AppAssetsAssetKeyIndexImport } from './routes/_app/assets.$assetKey.index'
import { Route as AppProjectsProjectKeyCostsImport } from './routes/_app/projects.$projectKey.costs'
import { Route as AppProjectsProjectKeyAuditImport } from './routes/_app/projects.$projectKey.audit'
import { Route as AppProjectsProjectKeyAttachmentsImport } from './routes/_app/projects.$projectKey.attachments'
import { Route as AppProductsProductKeyAuditImport } from './routes/_app/products.$productKey.audit'
import { Route as AppProductsProductKeyAssetsImport } from './routes/_app/products.$productKey.assets'
import { Route as AppAssetsAssetKeyMetadataImport } from './routes/_app/assets.$assetKey.metadata'
import { Route as AppAssetsAssetKeyAuditImport } from './routes/_app/assets.$assetKey.audit'
import { Route as AppAssetsAssetKeyAttachmentsImport } from './routes/_app/assets.$assetKey.attachments'
import { Route as AppAssetsAssetKeyAccountingImport } from './routes/_app/assets.$assetKey.accounting'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AppRoute = AppImport.update({
  id: '/_app',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AppOrganizationRoute = AppOrganizationImport.update({
  id: '/organization',
  path: '/organization',
  getParentRoute: () => AppRoute,
} as any)

const AppDashboardRoute = AppDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => AppRoute,
} as any)

const AppAccountRoute = AppAccountImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => AppRoute,
} as any)

const AppReportsIndexRoute = AppReportsIndexImport.update({
  id: '/reports/',
  path: '/reports/',
  getParentRoute: () => AppRoute,
} as any)

const AppProjectsIndexRoute = AppProjectsIndexImport.update({
  id: '/projects/',
  path: '/projects/',
  getParentRoute: () => AppRoute,
} as any)

const AppProductsIndexRoute = AppProductsIndexImport.update({
  id: '/products/',
  path: '/products/',
  getParentRoute: () => AppRoute,
} as any)

const AppLocationsIndexRoute = AppLocationsIndexImport.update({
  id: '/locations/',
  path: '/locations/',
  getParentRoute: () => AppRoute,
} as any)

const AppCustomersIndexRoute = AppCustomersIndexImport.update({
  id: '/customers/',
  path: '/customers/',
  getParentRoute: () => AppRoute,
} as any)

const AppAssetsIndexRoute = AppAssetsIndexImport.update({
  id: '/assets/',
  path: '/assets/',
  getParentRoute: () => AppRoute,
} as any)

const AppReportsUpcomingProjectsRoute = AppReportsUpcomingProjectsImport.update(
  {
    id: '/reports/upcoming-projects',
    path: '/reports/upcoming-projects',
    getParentRoute: () => AppRoute,
  } as any,
)

const AppReportsUpcomingProjectStartsRoute =
  AppReportsUpcomingProjectStartsImport.update({
    id: '/reports/upcoming-project-starts',
    path: '/reports/upcoming-project-starts',
    getParentRoute: () => AppRoute,
  } as any)

const AppReportsUpcomingProjectCompletionsRoute =
  AppReportsUpcomingProjectCompletionsImport.update({
    id: '/reports/upcoming-project-completions',
    path: '/reports/upcoming-project-completions',
    getParentRoute: () => AppRoute,
  } as any)

const AppReportsTransitRoute = AppReportsTransitImport.update({
  id: '/reports/transit',
  path: '/reports/transit',
  getParentRoute: () => AppRoute,
} as any)

const AppReportsPastProjectsRoute = AppReportsPastProjectsImport.update({
  id: '/reports/past-projects',
  path: '/reports/past-projects',
  getParentRoute: () => AppRoute,
} as any)

const AppReportsInventoryRoute = AppReportsInventoryImport.update({
  id: '/reports/inventory',
  path: '/reports/inventory',
  getParentRoute: () => AppRoute,
} as any)

const AppReportsFieldRoute = AppReportsFieldImport.update({
  id: '/reports/field',
  path: '/reports/field',
  getParentRoute: () => AppRoute,
} as any)

const AppReportsActiveProjectsRoute = AppReportsActiveProjectsImport.update({
  id: '/reports/active-projects',
  path: '/reports/active-projects',
  getParentRoute: () => AppRoute,
} as any)

const AppProjectsProjectKeyRoute = AppProjectsProjectKeyImport.update({
  id: '/projects/$projectKey',
  path: '/projects/$projectKey',
  getParentRoute: () => AppRoute,
} as any)

const AppProductsProductKeyRoute = AppProductsProductKeyImport.update({
  id: '/products/$productKey',
  path: '/products/$productKey',
  getParentRoute: () => AppRoute,
} as any)

const AppDashboardProductRoute = AppDashboardProductImport.update({
  id: '/product',
  path: '/product',
  getParentRoute: () => AppDashboardRoute,
} as any)

const AppDashboardLocationRoute = AppDashboardLocationImport.update({
  id: '/location',
  path: '/location',
  getParentRoute: () => AppDashboardRoute,
} as any)

const AppDashboardCustomerRoute = AppDashboardCustomerImport.update({
  id: '/customer',
  path: '/customer',
  getParentRoute: () => AppDashboardRoute,
} as any)

const AppAssetsAssetKeyRoute = AppAssetsAssetKeyImport.update({
  id: '/assets/$assetKey',
  path: '/assets/$assetKey',
  getParentRoute: () => AppRoute,
} as any)

const AppProjectsProjectKeyIndexRoute = AppProjectsProjectKeyIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => AppProjectsProjectKeyRoute,
  } as any,
)

const AppProductsProductKeyIndexRoute = AppProductsProductKeyIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => AppProductsProductKeyRoute,
  } as any,
)

const AppAssetsAssetKeyIndexRoute = AppAssetsAssetKeyIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppAssetsAssetKeyRoute,
} as any)

const AppProjectsProjectKeyCostsRoute = AppProjectsProjectKeyCostsImport.update(
  {
    id: '/costs',
    path: '/costs',
    getParentRoute: () => AppProjectsProjectKeyRoute,
  } as any,
)

const AppProjectsProjectKeyAuditRoute = AppProjectsProjectKeyAuditImport.update(
  {
    id: '/audit',
    path: '/audit',
    getParentRoute: () => AppProjectsProjectKeyRoute,
  } as any,
)

const AppProjectsProjectKeyAttachmentsRoute =
  AppProjectsProjectKeyAttachmentsImport.update({
    id: '/attachments',
    path: '/attachments',
    getParentRoute: () => AppProjectsProjectKeyRoute,
  } as any)

const AppProductsProductKeyAuditRoute = AppProductsProductKeyAuditImport.update(
  {
    id: '/audit',
    path: '/audit',
    getParentRoute: () => AppProductsProductKeyRoute,
  } as any,
)

const AppProductsProductKeyAssetsRoute =
  AppProductsProductKeyAssetsImport.update({
    id: '/assets',
    path: '/assets',
    getParentRoute: () => AppProductsProductKeyRoute,
  } as any)

const AppAssetsAssetKeyMetadataRoute = AppAssetsAssetKeyMetadataImport.update({
  id: '/metadata',
  path: '/metadata',
  getParentRoute: () => AppAssetsAssetKeyRoute,
} as any)

const AppAssetsAssetKeyAuditRoute = AppAssetsAssetKeyAuditImport.update({
  id: '/audit',
  path: '/audit',
  getParentRoute: () => AppAssetsAssetKeyRoute,
} as any)

const AppAssetsAssetKeyAttachmentsRoute =
  AppAssetsAssetKeyAttachmentsImport.update({
    id: '/attachments',
    path: '/attachments',
    getParentRoute: () => AppAssetsAssetKeyRoute,
  } as any)

const AppAssetsAssetKeyAccountingRoute =
  AppAssetsAssetKeyAccountingImport.update({
    id: '/accounting',
    path: '/accounting',
    getParentRoute: () => AppAssetsAssetKeyRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_app': {
      id: '/_app'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_app/account': {
      id: '/_app/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AppAccountImport
      parentRoute: typeof AppImport
    }
    '/_app/dashboard': {
      id: '/_app/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof AppDashboardImport
      parentRoute: typeof AppImport
    }
    '/_app/organization': {
      id: '/_app/organization'
      path: '/organization'
      fullPath: '/organization'
      preLoaderRoute: typeof AppOrganizationImport
      parentRoute: typeof AppImport
    }
    '/_app/assets/$assetKey': {
      id: '/_app/assets/$assetKey'
      path: '/assets/$assetKey'
      fullPath: '/assets/$assetKey'
      preLoaderRoute: typeof AppAssetsAssetKeyImport
      parentRoute: typeof AppImport
    }
    '/_app/dashboard/customer': {
      id: '/_app/dashboard/customer'
      path: '/customer'
      fullPath: '/dashboard/customer'
      preLoaderRoute: typeof AppDashboardCustomerImport
      parentRoute: typeof AppDashboardImport
    }
    '/_app/dashboard/location': {
      id: '/_app/dashboard/location'
      path: '/location'
      fullPath: '/dashboard/location'
      preLoaderRoute: typeof AppDashboardLocationImport
      parentRoute: typeof AppDashboardImport
    }
    '/_app/dashboard/product': {
      id: '/_app/dashboard/product'
      path: '/product'
      fullPath: '/dashboard/product'
      preLoaderRoute: typeof AppDashboardProductImport
      parentRoute: typeof AppDashboardImport
    }
    '/_app/products/$productKey': {
      id: '/_app/products/$productKey'
      path: '/products/$productKey'
      fullPath: '/products/$productKey'
      preLoaderRoute: typeof AppProductsProductKeyImport
      parentRoute: typeof AppImport
    }
    '/_app/projects/$projectKey': {
      id: '/_app/projects/$projectKey'
      path: '/projects/$projectKey'
      fullPath: '/projects/$projectKey'
      preLoaderRoute: typeof AppProjectsProjectKeyImport
      parentRoute: typeof AppImport
    }
    '/_app/reports/active-projects': {
      id: '/_app/reports/active-projects'
      path: '/reports/active-projects'
      fullPath: '/reports/active-projects'
      preLoaderRoute: typeof AppReportsActiveProjectsImport
      parentRoute: typeof AppImport
    }
    '/_app/reports/field': {
      id: '/_app/reports/field'
      path: '/reports/field'
      fullPath: '/reports/field'
      preLoaderRoute: typeof AppReportsFieldImport
      parentRoute: typeof AppImport
    }
    '/_app/reports/inventory': {
      id: '/_app/reports/inventory'
      path: '/reports/inventory'
      fullPath: '/reports/inventory'
      preLoaderRoute: typeof AppReportsInventoryImport
      parentRoute: typeof AppImport
    }
    '/_app/reports/past-projects': {
      id: '/_app/reports/past-projects'
      path: '/reports/past-projects'
      fullPath: '/reports/past-projects'
      preLoaderRoute: typeof AppReportsPastProjectsImport
      parentRoute: typeof AppImport
    }
    '/_app/reports/transit': {
      id: '/_app/reports/transit'
      path: '/reports/transit'
      fullPath: '/reports/transit'
      preLoaderRoute: typeof AppReportsTransitImport
      parentRoute: typeof AppImport
    }
    '/_app/reports/upcoming-project-completions': {
      id: '/_app/reports/upcoming-project-completions'
      path: '/reports/upcoming-project-completions'
      fullPath: '/reports/upcoming-project-completions'
      preLoaderRoute: typeof AppReportsUpcomingProjectCompletionsImport
      parentRoute: typeof AppImport
    }
    '/_app/reports/upcoming-project-starts': {
      id: '/_app/reports/upcoming-project-starts'
      path: '/reports/upcoming-project-starts'
      fullPath: '/reports/upcoming-project-starts'
      preLoaderRoute: typeof AppReportsUpcomingProjectStartsImport
      parentRoute: typeof AppImport
    }
    '/_app/reports/upcoming-projects': {
      id: '/_app/reports/upcoming-projects'
      path: '/reports/upcoming-projects'
      fullPath: '/reports/upcoming-projects'
      preLoaderRoute: typeof AppReportsUpcomingProjectsImport
      parentRoute: typeof AppImport
    }
    '/_app/assets/': {
      id: '/_app/assets/'
      path: '/assets'
      fullPath: '/assets'
      preLoaderRoute: typeof AppAssetsIndexImport
      parentRoute: typeof AppImport
    }
    '/_app/customers/': {
      id: '/_app/customers/'
      path: '/customers'
      fullPath: '/customers'
      preLoaderRoute: typeof AppCustomersIndexImport
      parentRoute: typeof AppImport
    }
    '/_app/locations/': {
      id: '/_app/locations/'
      path: '/locations'
      fullPath: '/locations'
      preLoaderRoute: typeof AppLocationsIndexImport
      parentRoute: typeof AppImport
    }
    '/_app/products/': {
      id: '/_app/products/'
      path: '/products'
      fullPath: '/products'
      preLoaderRoute: typeof AppProductsIndexImport
      parentRoute: typeof AppImport
    }
    '/_app/projects/': {
      id: '/_app/projects/'
      path: '/projects'
      fullPath: '/projects'
      preLoaderRoute: typeof AppProjectsIndexImport
      parentRoute: typeof AppImport
    }
    '/_app/reports/': {
      id: '/_app/reports/'
      path: '/reports'
      fullPath: '/reports'
      preLoaderRoute: typeof AppReportsIndexImport
      parentRoute: typeof AppImport
    }
    '/_app/assets/$assetKey/accounting': {
      id: '/_app/assets/$assetKey/accounting'
      path: '/accounting'
      fullPath: '/assets/$assetKey/accounting'
      preLoaderRoute: typeof AppAssetsAssetKeyAccountingImport
      parentRoute: typeof AppAssetsAssetKeyImport
    }
    '/_app/assets/$assetKey/attachments': {
      id: '/_app/assets/$assetKey/attachments'
      path: '/attachments'
      fullPath: '/assets/$assetKey/attachments'
      preLoaderRoute: typeof AppAssetsAssetKeyAttachmentsImport
      parentRoute: typeof AppAssetsAssetKeyImport
    }
    '/_app/assets/$assetKey/audit': {
      id: '/_app/assets/$assetKey/audit'
      path: '/audit'
      fullPath: '/assets/$assetKey/audit'
      preLoaderRoute: typeof AppAssetsAssetKeyAuditImport
      parentRoute: typeof AppAssetsAssetKeyImport
    }
    '/_app/assets/$assetKey/metadata': {
      id: '/_app/assets/$assetKey/metadata'
      path: '/metadata'
      fullPath: '/assets/$assetKey/metadata'
      preLoaderRoute: typeof AppAssetsAssetKeyMetadataImport
      parentRoute: typeof AppAssetsAssetKeyImport
    }
    '/_app/products/$productKey/assets': {
      id: '/_app/products/$productKey/assets'
      path: '/assets'
      fullPath: '/products/$productKey/assets'
      preLoaderRoute: typeof AppProductsProductKeyAssetsImport
      parentRoute: typeof AppProductsProductKeyImport
    }
    '/_app/products/$productKey/audit': {
      id: '/_app/products/$productKey/audit'
      path: '/audit'
      fullPath: '/products/$productKey/audit'
      preLoaderRoute: typeof AppProductsProductKeyAuditImport
      parentRoute: typeof AppProductsProductKeyImport
    }
    '/_app/projects/$projectKey/attachments': {
      id: '/_app/projects/$projectKey/attachments'
      path: '/attachments'
      fullPath: '/projects/$projectKey/attachments'
      preLoaderRoute: typeof AppProjectsProjectKeyAttachmentsImport
      parentRoute: typeof AppProjectsProjectKeyImport
    }
    '/_app/projects/$projectKey/audit': {
      id: '/_app/projects/$projectKey/audit'
      path: '/audit'
      fullPath: '/projects/$projectKey/audit'
      preLoaderRoute: typeof AppProjectsProjectKeyAuditImport
      parentRoute: typeof AppProjectsProjectKeyImport
    }
    '/_app/projects/$projectKey/costs': {
      id: '/_app/projects/$projectKey/costs'
      path: '/costs'
      fullPath: '/projects/$projectKey/costs'
      preLoaderRoute: typeof AppProjectsProjectKeyCostsImport
      parentRoute: typeof AppProjectsProjectKeyImport
    }
    '/_app/assets/$assetKey/': {
      id: '/_app/assets/$assetKey/'
      path: '/'
      fullPath: '/assets/$assetKey/'
      preLoaderRoute: typeof AppAssetsAssetKeyIndexImport
      parentRoute: typeof AppAssetsAssetKeyImport
    }
    '/_app/products/$productKey/': {
      id: '/_app/products/$productKey/'
      path: '/'
      fullPath: '/products/$productKey/'
      preLoaderRoute: typeof AppProductsProductKeyIndexImport
      parentRoute: typeof AppProductsProductKeyImport
    }
    '/_app/projects/$projectKey/': {
      id: '/_app/projects/$projectKey/'
      path: '/'
      fullPath: '/projects/$projectKey/'
      preLoaderRoute: typeof AppProjectsProjectKeyIndexImport
      parentRoute: typeof AppProjectsProjectKeyImport
    }
  }
}

// Create and export the route tree

interface AppDashboardRouteChildren {
  AppDashboardCustomerRoute: typeof AppDashboardCustomerRoute
  AppDashboardLocationRoute: typeof AppDashboardLocationRoute
  AppDashboardProductRoute: typeof AppDashboardProductRoute
}

const AppDashboardRouteChildren: AppDashboardRouteChildren = {
  AppDashboardCustomerRoute: AppDashboardCustomerRoute,
  AppDashboardLocationRoute: AppDashboardLocationRoute,
  AppDashboardProductRoute: AppDashboardProductRoute,
}

const AppDashboardRouteWithChildren = AppDashboardRoute._addFileChildren(
  AppDashboardRouteChildren,
)

interface AppAssetsAssetKeyRouteChildren {
  AppAssetsAssetKeyAccountingRoute: typeof AppAssetsAssetKeyAccountingRoute
  AppAssetsAssetKeyAttachmentsRoute: typeof AppAssetsAssetKeyAttachmentsRoute
  AppAssetsAssetKeyAuditRoute: typeof AppAssetsAssetKeyAuditRoute
  AppAssetsAssetKeyMetadataRoute: typeof AppAssetsAssetKeyMetadataRoute
  AppAssetsAssetKeyIndexRoute: typeof AppAssetsAssetKeyIndexRoute
}

const AppAssetsAssetKeyRouteChildren: AppAssetsAssetKeyRouteChildren = {
  AppAssetsAssetKeyAccountingRoute: AppAssetsAssetKeyAccountingRoute,
  AppAssetsAssetKeyAttachmentsRoute: AppAssetsAssetKeyAttachmentsRoute,
  AppAssetsAssetKeyAuditRoute: AppAssetsAssetKeyAuditRoute,
  AppAssetsAssetKeyMetadataRoute: AppAssetsAssetKeyMetadataRoute,
  AppAssetsAssetKeyIndexRoute: AppAssetsAssetKeyIndexRoute,
}

const AppAssetsAssetKeyRouteWithChildren =
  AppAssetsAssetKeyRoute._addFileChildren(AppAssetsAssetKeyRouteChildren)

interface AppProductsProductKeyRouteChildren {
  AppProductsProductKeyAssetsRoute: typeof AppProductsProductKeyAssetsRoute
  AppProductsProductKeyAuditRoute: typeof AppProductsProductKeyAuditRoute
  AppProductsProductKeyIndexRoute: typeof AppProductsProductKeyIndexRoute
}

const AppProductsProductKeyRouteChildren: AppProductsProductKeyRouteChildren = {
  AppProductsProductKeyAssetsRoute: AppProductsProductKeyAssetsRoute,
  AppProductsProductKeyAuditRoute: AppProductsProductKeyAuditRoute,
  AppProductsProductKeyIndexRoute: AppProductsProductKeyIndexRoute,
}

const AppProductsProductKeyRouteWithChildren =
  AppProductsProductKeyRoute._addFileChildren(
    AppProductsProductKeyRouteChildren,
  )

interface AppProjectsProjectKeyRouteChildren {
  AppProjectsProjectKeyAttachmentsRoute: typeof AppProjectsProjectKeyAttachmentsRoute
  AppProjectsProjectKeyAuditRoute: typeof AppProjectsProjectKeyAuditRoute
  AppProjectsProjectKeyCostsRoute: typeof AppProjectsProjectKeyCostsRoute
  AppProjectsProjectKeyIndexRoute: typeof AppProjectsProjectKeyIndexRoute
}

const AppProjectsProjectKeyRouteChildren: AppProjectsProjectKeyRouteChildren = {
  AppProjectsProjectKeyAttachmentsRoute: AppProjectsProjectKeyAttachmentsRoute,
  AppProjectsProjectKeyAuditRoute: AppProjectsProjectKeyAuditRoute,
  AppProjectsProjectKeyCostsRoute: AppProjectsProjectKeyCostsRoute,
  AppProjectsProjectKeyIndexRoute: AppProjectsProjectKeyIndexRoute,
}

const AppProjectsProjectKeyRouteWithChildren =
  AppProjectsProjectKeyRoute._addFileChildren(
    AppProjectsProjectKeyRouteChildren,
  )

interface AppRouteChildren {
  AppAccountRoute: typeof AppAccountRoute
  AppDashboardRoute: typeof AppDashboardRouteWithChildren
  AppOrganizationRoute: typeof AppOrganizationRoute
  AppAssetsAssetKeyRoute: typeof AppAssetsAssetKeyRouteWithChildren
  AppProductsProductKeyRoute: typeof AppProductsProductKeyRouteWithChildren
  AppProjectsProjectKeyRoute: typeof AppProjectsProjectKeyRouteWithChildren
  AppReportsActiveProjectsRoute: typeof AppReportsActiveProjectsRoute
  AppReportsFieldRoute: typeof AppReportsFieldRoute
  AppReportsInventoryRoute: typeof AppReportsInventoryRoute
  AppReportsPastProjectsRoute: typeof AppReportsPastProjectsRoute
  AppReportsTransitRoute: typeof AppReportsTransitRoute
  AppReportsUpcomingProjectCompletionsRoute: typeof AppReportsUpcomingProjectCompletionsRoute
  AppReportsUpcomingProjectStartsRoute: typeof AppReportsUpcomingProjectStartsRoute
  AppReportsUpcomingProjectsRoute: typeof AppReportsUpcomingProjectsRoute
  AppAssetsIndexRoute: typeof AppAssetsIndexRoute
  AppCustomersIndexRoute: typeof AppCustomersIndexRoute
  AppLocationsIndexRoute: typeof AppLocationsIndexRoute
  AppProductsIndexRoute: typeof AppProductsIndexRoute
  AppProjectsIndexRoute: typeof AppProjectsIndexRoute
  AppReportsIndexRoute: typeof AppReportsIndexRoute
}

const AppRouteChildren: AppRouteChildren = {
  AppAccountRoute: AppAccountRoute,
  AppDashboardRoute: AppDashboardRouteWithChildren,
  AppOrganizationRoute: AppOrganizationRoute,
  AppAssetsAssetKeyRoute: AppAssetsAssetKeyRouteWithChildren,
  AppProductsProductKeyRoute: AppProductsProductKeyRouteWithChildren,
  AppProjectsProjectKeyRoute: AppProjectsProjectKeyRouteWithChildren,
  AppReportsActiveProjectsRoute: AppReportsActiveProjectsRoute,
  AppReportsFieldRoute: AppReportsFieldRoute,
  AppReportsInventoryRoute: AppReportsInventoryRoute,
  AppReportsPastProjectsRoute: AppReportsPastProjectsRoute,
  AppReportsTransitRoute: AppReportsTransitRoute,
  AppReportsUpcomingProjectCompletionsRoute:
    AppReportsUpcomingProjectCompletionsRoute,
  AppReportsUpcomingProjectStartsRoute: AppReportsUpcomingProjectStartsRoute,
  AppReportsUpcomingProjectsRoute: AppReportsUpcomingProjectsRoute,
  AppAssetsIndexRoute: AppAssetsIndexRoute,
  AppCustomersIndexRoute: AppCustomersIndexRoute,
  AppLocationsIndexRoute: AppLocationsIndexRoute,
  AppProductsIndexRoute: AppProductsIndexRoute,
  AppProjectsIndexRoute: AppProjectsIndexRoute,
  AppReportsIndexRoute: AppReportsIndexRoute,
}

const AppRouteWithChildren = AppRoute._addFileChildren(AppRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AppRouteWithChildren
  '/login': typeof LoginRoute
  '/account': typeof AppAccountRoute
  '/dashboard': typeof AppDashboardRouteWithChildren
  '/organization': typeof AppOrganizationRoute
  '/assets/$assetKey': typeof AppAssetsAssetKeyRouteWithChildren
  '/dashboard/customer': typeof AppDashboardCustomerRoute
  '/dashboard/location': typeof AppDashboardLocationRoute
  '/dashboard/product': typeof AppDashboardProductRoute
  '/products/$productKey': typeof AppProductsProductKeyRouteWithChildren
  '/projects/$projectKey': typeof AppProjectsProjectKeyRouteWithChildren
  '/reports/active-projects': typeof AppReportsActiveProjectsRoute
  '/reports/field': typeof AppReportsFieldRoute
  '/reports/inventory': typeof AppReportsInventoryRoute
  '/reports/past-projects': typeof AppReportsPastProjectsRoute
  '/reports/transit': typeof AppReportsTransitRoute
  '/reports/upcoming-project-completions': typeof AppReportsUpcomingProjectCompletionsRoute
  '/reports/upcoming-project-starts': typeof AppReportsUpcomingProjectStartsRoute
  '/reports/upcoming-projects': typeof AppReportsUpcomingProjectsRoute
  '/assets': typeof AppAssetsIndexRoute
  '/customers': typeof AppCustomersIndexRoute
  '/locations': typeof AppLocationsIndexRoute
  '/products': typeof AppProductsIndexRoute
  '/projects': typeof AppProjectsIndexRoute
  '/reports': typeof AppReportsIndexRoute
  '/assets/$assetKey/accounting': typeof AppAssetsAssetKeyAccountingRoute
  '/assets/$assetKey/attachments': typeof AppAssetsAssetKeyAttachmentsRoute
  '/assets/$assetKey/audit': typeof AppAssetsAssetKeyAuditRoute
  '/assets/$assetKey/metadata': typeof AppAssetsAssetKeyMetadataRoute
  '/products/$productKey/assets': typeof AppProductsProductKeyAssetsRoute
  '/products/$productKey/audit': typeof AppProductsProductKeyAuditRoute
  '/projects/$projectKey/attachments': typeof AppProjectsProjectKeyAttachmentsRoute
  '/projects/$projectKey/audit': typeof AppProjectsProjectKeyAuditRoute
  '/projects/$projectKey/costs': typeof AppProjectsProjectKeyCostsRoute
  '/assets/$assetKey/': typeof AppAssetsAssetKeyIndexRoute
  '/products/$productKey/': typeof AppProductsProductKeyIndexRoute
  '/projects/$projectKey/': typeof AppProjectsProjectKeyIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AppRouteWithChildren
  '/login': typeof LoginRoute
  '/account': typeof AppAccountRoute
  '/dashboard': typeof AppDashboardRouteWithChildren
  '/organization': typeof AppOrganizationRoute
  '/dashboard/customer': typeof AppDashboardCustomerRoute
  '/dashboard/location': typeof AppDashboardLocationRoute
  '/dashboard/product': typeof AppDashboardProductRoute
  '/reports/active-projects': typeof AppReportsActiveProjectsRoute
  '/reports/field': typeof AppReportsFieldRoute
  '/reports/inventory': typeof AppReportsInventoryRoute
  '/reports/past-projects': typeof AppReportsPastProjectsRoute
  '/reports/transit': typeof AppReportsTransitRoute
  '/reports/upcoming-project-completions': typeof AppReportsUpcomingProjectCompletionsRoute
  '/reports/upcoming-project-starts': typeof AppReportsUpcomingProjectStartsRoute
  '/reports/upcoming-projects': typeof AppReportsUpcomingProjectsRoute
  '/assets': typeof AppAssetsIndexRoute
  '/customers': typeof AppCustomersIndexRoute
  '/locations': typeof AppLocationsIndexRoute
  '/products': typeof AppProductsIndexRoute
  '/projects': typeof AppProjectsIndexRoute
  '/reports': typeof AppReportsIndexRoute
  '/assets/$assetKey/accounting': typeof AppAssetsAssetKeyAccountingRoute
  '/assets/$assetKey/attachments': typeof AppAssetsAssetKeyAttachmentsRoute
  '/assets/$assetKey/audit': typeof AppAssetsAssetKeyAuditRoute
  '/assets/$assetKey/metadata': typeof AppAssetsAssetKeyMetadataRoute
  '/products/$productKey/assets': typeof AppProductsProductKeyAssetsRoute
  '/products/$productKey/audit': typeof AppProductsProductKeyAuditRoute
  '/projects/$projectKey/attachments': typeof AppProjectsProjectKeyAttachmentsRoute
  '/projects/$projectKey/audit': typeof AppProjectsProjectKeyAuditRoute
  '/projects/$projectKey/costs': typeof AppProjectsProjectKeyCostsRoute
  '/assets/$assetKey': typeof AppAssetsAssetKeyIndexRoute
  '/products/$productKey': typeof AppProductsProductKeyIndexRoute
  '/projects/$projectKey': typeof AppProjectsProjectKeyIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_app': typeof AppRouteWithChildren
  '/login': typeof LoginRoute
  '/_app/account': typeof AppAccountRoute
  '/_app/dashboard': typeof AppDashboardRouteWithChildren
  '/_app/organization': typeof AppOrganizationRoute
  '/_app/assets/$assetKey': typeof AppAssetsAssetKeyRouteWithChildren
  '/_app/dashboard/customer': typeof AppDashboardCustomerRoute
  '/_app/dashboard/location': typeof AppDashboardLocationRoute
  '/_app/dashboard/product': typeof AppDashboardProductRoute
  '/_app/products/$productKey': typeof AppProductsProductKeyRouteWithChildren
  '/_app/projects/$projectKey': typeof AppProjectsProjectKeyRouteWithChildren
  '/_app/reports/active-projects': typeof AppReportsActiveProjectsRoute
  '/_app/reports/field': typeof AppReportsFieldRoute
  '/_app/reports/inventory': typeof AppReportsInventoryRoute
  '/_app/reports/past-projects': typeof AppReportsPastProjectsRoute
  '/_app/reports/transit': typeof AppReportsTransitRoute
  '/_app/reports/upcoming-project-completions': typeof AppReportsUpcomingProjectCompletionsRoute
  '/_app/reports/upcoming-project-starts': typeof AppReportsUpcomingProjectStartsRoute
  '/_app/reports/upcoming-projects': typeof AppReportsUpcomingProjectsRoute
  '/_app/assets/': typeof AppAssetsIndexRoute
  '/_app/customers/': typeof AppCustomersIndexRoute
  '/_app/locations/': typeof AppLocationsIndexRoute
  '/_app/products/': typeof AppProductsIndexRoute
  '/_app/projects/': typeof AppProjectsIndexRoute
  '/_app/reports/': typeof AppReportsIndexRoute
  '/_app/assets/$assetKey/accounting': typeof AppAssetsAssetKeyAccountingRoute
  '/_app/assets/$assetKey/attachments': typeof AppAssetsAssetKeyAttachmentsRoute
  '/_app/assets/$assetKey/audit': typeof AppAssetsAssetKeyAuditRoute
  '/_app/assets/$assetKey/metadata': typeof AppAssetsAssetKeyMetadataRoute
  '/_app/products/$productKey/assets': typeof AppProductsProductKeyAssetsRoute
  '/_app/products/$productKey/audit': typeof AppProductsProductKeyAuditRoute
  '/_app/projects/$projectKey/attachments': typeof AppProjectsProjectKeyAttachmentsRoute
  '/_app/projects/$projectKey/audit': typeof AppProjectsProjectKeyAuditRoute
  '/_app/projects/$projectKey/costs': typeof AppProjectsProjectKeyCostsRoute
  '/_app/assets/$assetKey/': typeof AppAssetsAssetKeyIndexRoute
  '/_app/products/$productKey/': typeof AppProductsProductKeyIndexRoute
  '/_app/projects/$projectKey/': typeof AppProjectsProjectKeyIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/login'
    | '/account'
    | '/dashboard'
    | '/organization'
    | '/assets/$assetKey'
    | '/dashboard/customer'
    | '/dashboard/location'
    | '/dashboard/product'
    | '/products/$productKey'
    | '/projects/$projectKey'
    | '/reports/active-projects'
    | '/reports/field'
    | '/reports/inventory'
    | '/reports/past-projects'
    | '/reports/transit'
    | '/reports/upcoming-project-completions'
    | '/reports/upcoming-project-starts'
    | '/reports/upcoming-projects'
    | '/assets'
    | '/customers'
    | '/locations'
    | '/products'
    | '/projects'
    | '/reports'
    | '/assets/$assetKey/accounting'
    | '/assets/$assetKey/attachments'
    | '/assets/$assetKey/audit'
    | '/assets/$assetKey/metadata'
    | '/products/$productKey/assets'
    | '/products/$productKey/audit'
    | '/projects/$projectKey/attachments'
    | '/projects/$projectKey/audit'
    | '/projects/$projectKey/costs'
    | '/assets/$assetKey/'
    | '/products/$productKey/'
    | '/projects/$projectKey/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/login'
    | '/account'
    | '/dashboard'
    | '/organization'
    | '/dashboard/customer'
    | '/dashboard/location'
    | '/dashboard/product'
    | '/reports/active-projects'
    | '/reports/field'
    | '/reports/inventory'
    | '/reports/past-projects'
    | '/reports/transit'
    | '/reports/upcoming-project-completions'
    | '/reports/upcoming-project-starts'
    | '/reports/upcoming-projects'
    | '/assets'
    | '/customers'
    | '/locations'
    | '/products'
    | '/projects'
    | '/reports'
    | '/assets/$assetKey/accounting'
    | '/assets/$assetKey/attachments'
    | '/assets/$assetKey/audit'
    | '/assets/$assetKey/metadata'
    | '/products/$productKey/assets'
    | '/products/$productKey/audit'
    | '/projects/$projectKey/attachments'
    | '/projects/$projectKey/audit'
    | '/projects/$projectKey/costs'
    | '/assets/$assetKey'
    | '/products/$productKey'
    | '/projects/$projectKey'
  id:
    | '__root__'
    | '/'
    | '/_app'
    | '/login'
    | '/_app/account'
    | '/_app/dashboard'
    | '/_app/organization'
    | '/_app/assets/$assetKey'
    | '/_app/dashboard/customer'
    | '/_app/dashboard/location'
    | '/_app/dashboard/product'
    | '/_app/products/$productKey'
    | '/_app/projects/$projectKey'
    | '/_app/reports/active-projects'
    | '/_app/reports/field'
    | '/_app/reports/inventory'
    | '/_app/reports/past-projects'
    | '/_app/reports/transit'
    | '/_app/reports/upcoming-project-completions'
    | '/_app/reports/upcoming-project-starts'
    | '/_app/reports/upcoming-projects'
    | '/_app/assets/'
    | '/_app/customers/'
    | '/_app/locations/'
    | '/_app/products/'
    | '/_app/projects/'
    | '/_app/reports/'
    | '/_app/assets/$assetKey/accounting'
    | '/_app/assets/$assetKey/attachments'
    | '/_app/assets/$assetKey/audit'
    | '/_app/assets/$assetKey/metadata'
    | '/_app/products/$productKey/assets'
    | '/_app/products/$productKey/audit'
    | '/_app/projects/$projectKey/attachments'
    | '/_app/projects/$projectKey/audit'
    | '/_app/projects/$projectKey/costs'
    | '/_app/assets/$assetKey/'
    | '/_app/products/$productKey/'
    | '/_app/projects/$projectKey/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AppRoute: typeof AppRouteWithChildren
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AppRoute: AppRouteWithChildren,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_app",
        "/login"
      ]
    },
    "/": {
      "filePath": "index.ts"
    },
    "/_app": {
      "filePath": "_app.tsx",
      "children": [
        "/_app/account",
        "/_app/dashboard",
        "/_app/organization",
        "/_app/assets/$assetKey",
        "/_app/products/$productKey",
        "/_app/projects/$projectKey",
        "/_app/reports/active-projects",
        "/_app/reports/field",
        "/_app/reports/inventory",
        "/_app/reports/past-projects",
        "/_app/reports/transit",
        "/_app/reports/upcoming-project-completions",
        "/_app/reports/upcoming-project-starts",
        "/_app/reports/upcoming-projects",
        "/_app/assets/",
        "/_app/customers/",
        "/_app/locations/",
        "/_app/products/",
        "/_app/projects/",
        "/_app/reports/"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_app/account": {
      "filePath": "_app/account.tsx",
      "parent": "/_app"
    },
    "/_app/dashboard": {
      "filePath": "_app/dashboard.tsx",
      "parent": "/_app",
      "children": [
        "/_app/dashboard/customer",
        "/_app/dashboard/location",
        "/_app/dashboard/product"
      ]
    },
    "/_app/organization": {
      "filePath": "_app/organization.tsx",
      "parent": "/_app"
    },
    "/_app/assets/$assetKey": {
      "filePath": "_app/assets.$assetKey.tsx",
      "parent": "/_app",
      "children": [
        "/_app/assets/$assetKey/accounting",
        "/_app/assets/$assetKey/attachments",
        "/_app/assets/$assetKey/audit",
        "/_app/assets/$assetKey/metadata",
        "/_app/assets/$assetKey/"
      ]
    },
    "/_app/dashboard/customer": {
      "filePath": "_app/dashboard.customer.tsx",
      "parent": "/_app/dashboard"
    },
    "/_app/dashboard/location": {
      "filePath": "_app/dashboard.location.tsx",
      "parent": "/_app/dashboard"
    },
    "/_app/dashboard/product": {
      "filePath": "_app/dashboard.product.tsx",
      "parent": "/_app/dashboard"
    },
    "/_app/products/$productKey": {
      "filePath": "_app/products.$productKey.tsx",
      "parent": "/_app",
      "children": [
        "/_app/products/$productKey/assets",
        "/_app/products/$productKey/audit",
        "/_app/products/$productKey/"
      ]
    },
    "/_app/projects/$projectKey": {
      "filePath": "_app/projects.$projectKey.tsx",
      "parent": "/_app",
      "children": [
        "/_app/projects/$projectKey/attachments",
        "/_app/projects/$projectKey/audit",
        "/_app/projects/$projectKey/costs",
        "/_app/projects/$projectKey/"
      ]
    },
    "/_app/reports/active-projects": {
      "filePath": "_app/reports.active-projects.tsx",
      "parent": "/_app"
    },
    "/_app/reports/field": {
      "filePath": "_app/reports.field.tsx",
      "parent": "/_app"
    },
    "/_app/reports/inventory": {
      "filePath": "_app/reports.inventory.tsx",
      "parent": "/_app"
    },
    "/_app/reports/past-projects": {
      "filePath": "_app/reports.past-projects.tsx",
      "parent": "/_app"
    },
    "/_app/reports/transit": {
      "filePath": "_app/reports.transit.tsx",
      "parent": "/_app"
    },
    "/_app/reports/upcoming-project-completions": {
      "filePath": "_app/reports.upcoming-project-completions.tsx",
      "parent": "/_app"
    },
    "/_app/reports/upcoming-project-starts": {
      "filePath": "_app/reports.upcoming-project-starts.tsx",
      "parent": "/_app"
    },
    "/_app/reports/upcoming-projects": {
      "filePath": "_app/reports.upcoming-projects.tsx",
      "parent": "/_app"
    },
    "/_app/assets/": {
      "filePath": "_app/assets.index.tsx",
      "parent": "/_app"
    },
    "/_app/customers/": {
      "filePath": "_app/customers.index.tsx",
      "parent": "/_app"
    },
    "/_app/locations/": {
      "filePath": "_app/locations.index.tsx",
      "parent": "/_app"
    },
    "/_app/products/": {
      "filePath": "_app/products.index.tsx",
      "parent": "/_app"
    },
    "/_app/projects/": {
      "filePath": "_app/projects.index.tsx",
      "parent": "/_app"
    },
    "/_app/reports/": {
      "filePath": "_app/reports.index.tsx",
      "parent": "/_app"
    },
    "/_app/assets/$assetKey/accounting": {
      "filePath": "_app/assets.$assetKey.accounting.tsx",
      "parent": "/_app/assets/$assetKey"
    },
    "/_app/assets/$assetKey/attachments": {
      "filePath": "_app/assets.$assetKey.attachments.tsx",
      "parent": "/_app/assets/$assetKey"
    },
    "/_app/assets/$assetKey/audit": {
      "filePath": "_app/assets.$assetKey.audit.tsx",
      "parent": "/_app/assets/$assetKey"
    },
    "/_app/assets/$assetKey/metadata": {
      "filePath": "_app/assets.$assetKey.metadata.tsx",
      "parent": "/_app/assets/$assetKey"
    },
    "/_app/products/$productKey/assets": {
      "filePath": "_app/products.$productKey.assets.tsx",
      "parent": "/_app/products/$productKey"
    },
    "/_app/products/$productKey/audit": {
      "filePath": "_app/products.$productKey.audit.tsx",
      "parent": "/_app/products/$productKey"
    },
    "/_app/projects/$projectKey/attachments": {
      "filePath": "_app/projects.$projectKey.attachments.tsx",
      "parent": "/_app/projects/$projectKey"
    },
    "/_app/projects/$projectKey/audit": {
      "filePath": "_app/projects.$projectKey.audit.tsx",
      "parent": "/_app/projects/$projectKey"
    },
    "/_app/projects/$projectKey/costs": {
      "filePath": "_app/projects.$projectKey.costs.tsx",
      "parent": "/_app/projects/$projectKey"
    },
    "/_app/assets/$assetKey/": {
      "filePath": "_app/assets.$assetKey.index.tsx",
      "parent": "/_app/assets/$assetKey"
    },
    "/_app/products/$productKey/": {
      "filePath": "_app/products.$productKey.index.tsx",
      "parent": "/_app/products/$productKey"
    },
    "/_app/projects/$projectKey/": {
      "filePath": "_app/projects.$projectKey.index.tsx",
      "parent": "/_app/projects/$projectKey"
    }
  }
}
ROUTE_MANIFEST_END */
